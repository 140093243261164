var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "custom-box"
  }, [_c('back-off', {
    attrs: {
      "url": _vm.backUrl,
      "title": "Diy"
    }
  }), _vm.showGuide ? _c('div', {
    staticClass: "guide",
    staticStyle: {
      "top": "46px"
    }
  }, [_c('div', {
    staticClass: "guide-container",
    staticStyle: {
      "top": "0%",
      "left": "0%",
      "width": "100vw",
      "height": "100vw"
    }
  }, [_c('div', {
    staticClass: "step",
    staticStyle: {
      "background-color": "black",
      "opacity": "0.7"
    }
  }, [_c('van-image', {
    attrs: {
      "src": _vm.guideList[_vm.guideIndex]
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c('van-loading', {
          staticStyle: {
            "color": "red"
          },
          attrs: {
            "type": "spinner",
            "size": "50"
          }
        })];
      },
      proxy: true
    }], null, false, 374717000)
  })], 1), _c('div', {
    staticStyle: {
      "margin-top": "0vw",
      "opacity": "0.5",
      "height": "100vh",
      "background-color": "black"
    }
  }, [_c('button', {
    staticStyle: {
      "top": "10%",
      "position": "relative",
      "background-color": "transparent",
      "width": "25vw",
      "height": "10vw",
      "border": "1px dashed white",
      "color": "white",
      "border-radius": "20px"
    },
    on: {
      "click": _vm.closeGuide
    }
  }, [_vm._v(_vm._s(_vm.guideContent[_vm.guideIndex]))])])])]) : _vm._e(), _vm.imagesLoaded && _vm.uploadPictureShow ? _c('div', {
    staticStyle: {
      "z-index": "233",
      "width": "100%",
      "height": "100vw",
      "position": "absolute",
      "overflow": "hidden"
    }
  }, [!_vm.userImgShow ? _c('van-uploader', {
    staticStyle: {
      "width": "100vw",
      "height": "100vw"
    },
    attrs: {
      "max-size": 26214400,
      "preview-image": false,
      "after-read": _vm.afterRead,
      "accept": "image/*"
    },
    on: {
      "oversize": _vm.onOversize
    }
  }, [_c('van-button', {
    staticStyle: {
      "width": "100vw",
      "height": "100vw",
      "opacity": "0"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _c('div', {
    ref: "targetDiv",
    staticClass: "custom-context-box-1",
    staticStyle: {
      "width": "100%",
      "height": "100vw",
      "position": "absolute",
      "overflow": "hidden"
    },
    attrs: {
      "id": "custom-context-box-1"
    },
    on: {
      "click": function ($event) {
        return _vm.canNotMove();
      }
    }
  }, [!_vm.userImgShow ? _c('van-image', {
    staticClass: "custom-canvas-image-box",
    attrs: {
      "id": "custom-canvas-image-box",
      "width": "100%",
      "height": "100vw",
      "src": _vm.canvasConfig.remindImg
    },
    on: {
      "load": _vm.imageLoaded
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c('van-loading', {
          staticStyle: {
            "color": "red"
          },
          attrs: {
            "type": "spinner",
            "size": "50"
          }
        })];
      },
      proxy: true
    }], null, false, 374717000)
  }) : _vm._e(), _vm.userImgShow ? _c('van-image', {
    staticClass: "custom-canvas-image-box",
    attrs: {
      "id": "custom-canvas-image-box",
      "width": "100%",
      "height": "100vw",
      "src": _vm.canvasConfig.bgImg
    },
    on: {
      "load": _vm.imageLoaded
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c('van-loading', {
          staticStyle: {
            "color": "red"
          },
          attrs: {
            "type": "spinner",
            "size": "50"
          }
        })];
      },
      proxy: true
    }], null, false, 374717000)
  }) : _vm._e(), _vm.imagesLoaded && _vm.cusAreaShow ? _c('div', {
    ref: "wrapper",
    staticClass: "custom-cusArea-box",
    style: {
      width: _vm.cusAreaW,
      height: _vm.cusAreaH,
      top: _vm.cusAreaY,
      left: _vm.cusAreaX,
      // border: cusAreaBorder,
      position: 'absolute'
    },
    attrs: {
      "id": "custom-cusArea-box"
    }
  }, [_vm.imagesLoaded && _vm.userImgShow ? _c('van-image', {
    ref: "userImage",
    staticClass: "custom-user-image-box",
    style: {
      width: _vm.userImgW,
      height: _vm.userImgH,
      top: _vm.userImgY,
      left: _vm.userImgX,
      position: 'relative'
    },
    attrs: {
      "id": "custom-user-image-box",
      "src": _vm.userImgData.src
    },
    on: {
      "load": _vm.onImageLoad
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c('van-loading', {
          staticStyle: {
            "color": "red"
          },
          attrs: {
            "type": "spinner",
            "size": "50"
          }
        })];
      },
      proxy: true
    }], null, false, 374717000)
  }) : _vm._e(), _c('div', {
    ref: "image",
    staticClass: "custom-user-image-option-box",
    staticStyle: {
      "z-index": "1000"
    },
    style: {
      width: _vm.userImgW,
      height: _vm.userImgH,
      top: _vm.userImgY,
      left: _vm.userImgX,
      border: _vm.operateBorder,
      position: 'absolute'
    },
    attrs: {
      "id": "custom-user-image-option-box"
    },
    on: {
      "click": function ($event) {
        return _vm.canMove();
      }
    }
  })], 1) : _vm._e(), _vm.userImgShow ? _c('van-image', {
    staticClass: "custom-cover-image-box",
    staticStyle: {
      "width": "100%",
      "height": "100vw",
      "position": "absolute",
      "z-index": "99",
      "top": "0",
      "left": "0"
    },
    attrs: {
      "id": "custom-canvas-image-box",
      "src": _vm.canvasConfig.coverImg
    }
  }) : _vm._e()], 1), _vm.imagesLoaded && _vm.userImgShow ? _c('van-uploader', {
    staticClass: "custom-upload-box",
    staticStyle: {
      "position": "absolute",
      "left": "0",
      "top": "115vw",
      "z-index": "999"
    },
    attrs: {
      "max-size": 26214400,
      "accept": "image/*",
      "after-read": _vm.afterRead
    },
    on: {
      "oversize": _vm.onOversize
    }
  }, [_c('van-button', {
    attrs: {
      "icon": "plus",
      "type": "primary",
      "color": "linear-gradient(to right, #ff6034, #ee0a24)"
    }
  }, [_vm._v("change your picture")])], 1) : _vm._e(), _vm.userImgShow && !_vm.canMovePicture ? _c('div', {
    staticStyle: {
      "background-color": "rgb(235, 235, 84)",
      "font-size": "4vw",
      "color": "#D56460",
      "position": "absolute",
      "left": "0",
      "top": "126vw",
      "height": "5vw",
      "width": "100vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('van-icon', {
    attrs: {
      "name": "info-o"
    }
  }), _vm._v(" "), _c('i', {
    staticStyle: {
      "width": "2vw"
    }
  }), _vm._v("   Click on your picture to take action.")], 1) : _vm._e(), _vm.userImgShow && _vm.canMovePicture ? _c('div', {
    staticStyle: {
      "background-color": "rgb(235, 235, 84)",
      "font-size": "4vw",
      "color": "#D56460",
      "position": "absolute",
      "left": "0",
      "top": "126vw",
      "height": "5vw",
      "width": "100vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('van-icon', {
    attrs: {
      "name": "info-o"
    }
  }), _vm._v(" "), _c('i', {
    staticStyle: {
      "width": "2vw"
    }
  }), _vm._v("   Now you can interact with your picture.")], 1) : _vm._e(), [_vm.userImgShow ? _c('van-button', {
    staticClass: "page-bottom-submit-box",
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "left": "0",
      "z-index": "12",
      "background-color": "#D56460",
      "width": "100vw",
      "height": "12.52vw",
      "font-size": "5vw",
      "font-weight": "bold"
    },
    attrs: {
      "type": "primary",
      "size": "large",
      "color": "#D56460"
    },
    on: {
      "click": function ($event) {
        return _vm.postToShoppingCart();
      }
    }
  }, [_vm._v(" " + _vm._s("Place Order") + " ")]) : _vm._e(), !_vm.userImgShow ? _c('van-uploader', {
    staticClass: "page-bottom-submit-box",
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "left": "0",
      "z-index": "12",
      "background-color": "#D56460",
      "width": "100vw",
      "height": "12.52vw"
    },
    attrs: {
      "max-size": 26214400,
      "preview-image": false,
      "after-read": _vm.afterRead,
      "accept": "image/*"
    },
    on: {
      "oversize": _vm.onOversize
    }
  }, [_c('van-button', {
    staticStyle: {
      "font-size": "5vw",
      "font-weight": "bold",
      "width": "100vw",
      "height": "12.52vw",
      "background-color": "#D56460",
      "border-color": "#D56460"
    },
    attrs: {
      "icon": "plus",
      "type": "primary",
      "size": "large"
    }
  }, [_vm._v("Upload Your Picture")])], 1) : _vm._e()], _vm.addAddressFlag ? _c('add-address-popup', {
    attrs: {
      "add-address-popup-show": _vm.addAddressFlag
    },
    on: {
      "callbackForAdd": _vm.callbackForAdd
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };